import { css } from '@emotion/react';
import React from 'react';
import { FeedType } from '../../../../models/feedType';
import { ImageFileData } from '../../../../models/image';
import { useFixedContainer } from '../../../FixedContainer/hook';
import Layout from '../../../Layout';
import NavigationBar from '../../../NavigationBar';
import {
  FeedDetailImageUpload,
  imageUploadContainerDefaultPadding,
} from '../components/ImageUpload';
import { FeedDetailUserHeader } from '../components/UserHeader';
import { feedDetailSectionStyle, feedDetailTextAreaStyle } from '../style';
import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useCoreApi } from '../../../../apis/core';
import { swrDefaultOption } from '../../../../utils/swr';
import Modal from '../../../Modal/Modal';
import ModalCheck from '../../../Modal/ModalCheck';
import { useLocation } from '@reach/router';
import { goPreviousPage } from '../../../../utils/route';

interface FeedDetailAddPageProps {
  accessToken: string;
  channelId: string;
  feedType: FeedType;
}

export default function FeedDetailAddPage({
  accessToken,
  channelId,
  feedType,
}: FeedDetailAddPageProps) {
  const coreApi = useCoreApi();
  const location = useLocation();

  const [text, setText] = React.useState<string>('');
  const [images, setImages] = React.useState<ImageFileData[]>([]);
  const [isOpenAlertModal, setIsOpenAlertModal] = React.useState(false);
  const [alertModalText, setAlertModalText] = React.useState('');

  const fixedEl = React.useRef<HTMLDivElement | null>(null);
  const textAreaEl = React.useRef<HTMLTextAreaElement | null>(null);

  const handleChangeText = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(e.target.value);
    },
    []
  );

  async function sendGetMeRequest() {
    return coreApi.getMe({
      accessToken,
    });
  }

  const handleFocusTextArea = React.useCallback(() => {
    if (!fixedEl.current) return;
    fixedEl.current.style.paddingBottom = `${imageUploadContainerDefaultPadding}px`;
  }, []);

  const handleBlurTextArea = React.useCallback(() => {
    if (!fixedEl.current) return;
    fixedEl.current.style.paddingBottom = `calc(${imageUploadContainerDefaultPadding}px + env(safe-area-inset-bottom))`;
  }, []);

  const { data: getMeData, error: getMeError } = useSWR(
    ['getMe', channelId],
    sendGetMeRequest,
    swrDefaultOption
  );

  React.useEffect(() => {
    console.error(getMeError);
  }, [getMeError]);

  async function sendPostCommunityCreatorFeedsRequest(
    key: string[],
    { arg }: { arg: { content: string; images: ImageFileData[] } }
  ) {
    return coreApi.postCommunityCreatorFeeds({
      accessToken,
      channelId,
      content: arg.content,
      images: arg.images,
      isPinned: false,
    });
  }

  async function sendPostCommunityUserFeedsRequest(
    key: string[],
    { arg }: { arg: { content: string; images: ImageFileData[] } }
  ) {
    return coreApi.postCommunityUserFeeds({
      accessToken,
      channelId,
      content: arg.content,
      images: arg.images,
    });
  }

  const {
    trigger: triggerPostCommunityFeeds,
    isMutating: isMutatingPostCommunityFeeds,
    error: postCommunityFeedsError,
  } = useSWRMutation(
    ['postCommunityFeeds', channelId],
    feedType === FeedType.CREATOR
      ? sendPostCommunityCreatorFeedsRequest
      : sendPostCommunityUserFeedsRequest
  );

  const handleCloseAlertModalHandler = React.useCallback(() => {
    setIsOpenAlertModal(false);
    setAlertModalText('');
    if (!postCommunityFeedsError) {
      // 성공했다면 이전 페이지로 보내기
      goPreviousPage(location);
    }
  }, [location, postCommunityFeedsError]);

  const openAlertModalHandler = React.useCallback((text: string) => {
    setIsOpenAlertModal(true);
    setAlertModalText(text);
  }, []);

  const isEnableClickSave = React.useMemo(
    () => !isMutatingPostCommunityFeeds && text !== '',
    [isMutatingPostCommunityFeeds, text]
  );

  const handleClickSave = React.useCallback(async () => {
    if (!isEnableClickSave) return;

    try {
      await triggerPostCommunityFeeds({
        content: text,
        images,
      });

      mutate(['mutateContentSectionFeeds', channelId, feedType]);
      openAlertModalHandler('글 작성에 성공하였습니다!');
    } catch (err) {
      console.error(err);
    }
  }, [
    channelId,
    feedType,
    images,
    isEnableClickSave,
    openAlertModalHandler,
    text,
    triggerPostCommunityFeeds,
  ]);

  return (
    <Layout>
      <NavigationBar
        rightButton={
          <div
            css={[
              saveButtonStyle,
              isEnableClickSave ? undefined : saveButtonDisabledStyle,
            ]}
            onClick={handleClickSave}
          >
            저장
          </div>
        }
      >
        {feedType === FeedType.CREATOR && '피드'}
        {feedType === FeedType.USER && '커뮤니티'} 게시물 추가
      </NavigationBar>
      <section css={feedDetailSectionStyle}>
        <FeedDetailUserHeader
          name={getMeData?.name || ''}
          profileImageUrl={getMeData?.profileImage}
        />
        <textarea
          ref={textAreaEl}
          value={text}
          onChange={handleChangeText}
          css={feedDetailTextAreaStyle}
          onFocus={handleFocusTextArea}
          onBlur={handleBlurTextArea}
        />
        <FeedDetailImageUpload
          ref={fixedEl}
          images={images}
          onChangeImages={setImages}
          channelId={channelId}
          accessToken={accessToken}
          css={imageUploadStyle}
        />
      </section>
      {isOpenAlertModal && (
        <Modal closeModal={handleCloseAlertModalHandler}>
          <ModalCheck
            closeModal={handleCloseAlertModalHandler}
            content={alertModalText}
          />
        </Modal>
      )}
    </Layout>
  );
}

const saveButtonStyle = css`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #c869ff;
`;

const saveButtonDisabledStyle = css`
  color: #cccccc;
`;

const imageUploadStyle = css`
  padding-bottom: calc(
    ${imageUploadContainerDefaultPadding}px + env(safe-area-inset-bottom)
  );
`;
